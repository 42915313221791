import React, { useState } from "react"

import { FormattedMessage, injectIntl } from "gatsby-plugin-intl"
import Layout from "../layouts/layout"
import SEO from "../components/seo"
import Herofooter from "../components/Herofooter"

import isInt from "validator/lib/isInt"

import "../styles/pricing.scss"

import { useForm } from "react-hook-form"

import { FaCubes, FaCloudDownloadAlt, FaComments } from "react-icons/fa"

const PricingPage = ({ intl }) => {
  const [submitted, data] = useState(true)
  const {
    register,
    handleSubmit,
    setValue,
    errors,
    formState: { isSubmitting },
  } = useForm({
    mode: "onSubmit",
    shouldFocusError: true,
  })

  const onSubmit = async data => {
    let totalPrice = 0
    if (data.totalUser === 1) {
      data.totalPrice = 0
    } else if (data.totalUser >= 2 && data.totalUser <= 5) {
      totalPrice += (data.totalUser - 1) * 27
    } else if (data.totalUser >= 6 && data.totalUser <= 20) {
      totalPrice += (5 - 1) * 27
      totalPrice += (data.totalUser - 5) * 21
    } else if (data.totalUser >= 21 && data.totalUser <= 50) {
      totalPrice += (5 - 1) * 27
      totalPrice += (20 - 5) * 21
      totalPrice += (data.totalUser - 20) * 15
    } else if (data.totalUser >= 51) {
      totalPrice += (5 - 1) * 27
      totalPrice += (20 - 5) * 21
      totalPrice += (50 - 20) * 15
      totalPrice += (data.totalUser - 50) * 12
    }
    setValue("totalPrice", totalPrice + " TL")
    // gtm tracking//tag manager
    if (typeof window !== "undefined") {
      var dataLayer = window.dataLayer || []
      dataLayer.push({
        event: "landingPriceCalculated",
        user: data.totalUser,
        price: totalPrice,
      })
    }

  }

  const showSubmitError = msg => <p className="msg-error">{msg}</p>

  const showForm = (
    <form onSubmit={handleSubmit(onSubmit)} name="calcPrice" id="calcPrice">
      <div className="row">
        <div className="col">
          <h3>
            <FormattedMessage id="LANDING.PAGE_PRICING.NUMBER_OF_USERS" />
          </h3>
        </div>
        <div className="col">
          <input
            type="number"
            min="1"
            id="totalUser"
            name="totalUser"
            placeholder="1"
            ref={register({
              required: true,
              validate: input => isInt(input),
            })}
            disabled={isSubmitting}
            className={errors.totalUser ? "warning" : null}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h3>
            <FormattedMessage id="LANDING.PAGE_PRICING.MONTHLY_AMOUNT" />
          </h3>
        </div>
        <div className="col">
          <input
            type="text"
            id="totalPrice"
            name="totalPrice"
            placeholder="0 TL"
            ref={register({})}
            disabled
            className={errors.totalPrice ? "warning" : null}
          />
        </div>
      </div>
      <div className="row">
        <button
          type="submit"
          className="cta-button"
          aria-label="Calculate"
          disabled={isSubmitting}
        >
          <FormattedMessage id="LANDING.CALCULATE" />
        </button>
      </div>
    </form>
  )

  return (
    <Layout>
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: "SEO.LANDING.PRICING.TITLE" })}
        description={intl.formatMessage({
          id: "SEO.LANDING.PRICING.DESCRIPTION",
        })}
        keywords={intl.formatMessage({ id: "SEO.LANDING.PRICING.KEYWORDS" })}
      />

      <div id="landing-pricing">
        <div className="tpnet-main-content">
          <tpnet-content>
            <article className="tpnet-article">
              <article className="tpnet-article-inner">
                <div className="tpnet-article-body">
                  <main>
                    <section className="landing-header">
                      <div
                        className="landing-header__nacho landing-header__nacho--default"
                        aria-hidden="true"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="3463.97px"
                          height="3947.35px"
                          viewBox="0 0 3463.97 3947.35"
                          preserveAspectRatio="xMinYMin"
                        >
                          <defs>
                            <clipPath id="landing-header-nacho_svg__clip-path">
                              <path
                                className="landing-header-nacho_svg__cls-1"
                                d="M-3593.5-2560.43c286.06-63.44 1878.61 2088.8 1793.43 2324.85s-2266.29 720.51-2442.7 542.47c-26-26.3-36.38-105.87-34.34-222.62 11.69-674.45 439.65-2590.53 683.61-2644.7z"
                              ></path>
                            </clipPath>
                            <clipPath id="landing-header-nacho_svg__clip-path-2">
                              <path
                                fill="none"
                                d="M-5206.6-3363.17h4882v3709h-4882z"
                              ></path>
                            </clipPath>
                            <clipPath id="landing-header-nacho_svg__clip-path-3">
                              <path
                                className="landing-header-nacho_svg__cls-1"
                                d="M33.7 3869.38C259.33 4130.56 3298.85 3677.6 3437.6 3362c20.45-46.59-5.48-153.88-65.65-302.79C3024.47 2198.87 1530.45-50.73 1190.5 0 791.92 59.49-192 3608.24 33.7 3869.38z"
                              ></path>
                            </clipPath>
                          </defs>
                          <g
                            clipPath="url(#landing-header-nacho_svg__clip-path)"
                            id="landing-header-nacho_svg__header-nacho"
                          >
                            <g clipPath="url(#landing-header-nacho_svg__clip-path-2)">
                              <path d="M-6731.56-24594.94l-221.11 27884.15L4247.1 3719.53l221.12-27884.15-11199.78-430.32z"></path>
                            </g>
                          </g>
                          <g
                            clipPath="url(#landing-header-nacho_svg__clip-path-3)"
                            id="landing-header-nacho_svg__landing-header-nacho"
                          >
                            <path
                              transform="rotate(-22 1175.988 2165.688)"
                              id="landing-header-background"
                              fill="currentColor"
                              d="M-530.36 155.86h3412.69v4019.63H-530.36z"
                            ></path>
                          </g>
                        </svg>
                      </div>
                      <header className="landing-header__text">
                        <div className="landing-header__inner-wrapper">
                          <tpnet-heading className="light">
                            <h1>
                              <FormattedMessage id="LANDING.PRICING" />
                            </h1>
                          </tpnet-heading>
                        </div>
                      </header>
                    </section>

                    <div className="content-wrapper">
                      <section className="card-wrapper">
                        <div className="row">
                          <div className="col">
                            <h2>
                              <FormattedMessage id="LANDING.PAGE_PRICING.CHOOSE_YOUR_PLAN" />
                            </h2>

                            <ul className="price-table">
                              <li>
                                <div className="inner-row">
                                  <div className="inner-col">
                                    <div className="name">
                                      <FormattedMessage id="LANDING.PAGE_PRICING.BASIC" />
                                    </div>
                                    <p className="desc">
                                      <FormattedMessage id="LANDING.PAGE_PRICING.r1_USER" />
                                    </p>
                                  </div>
                                  <div className="inner-col">
                                    <div className="price">
                                      <FormattedMessage id="LANDING.PAGE_PRICING.PRICE_BASIC" />
                                    </div>
                                    <p className="per-user">
                                      <FormattedMessage id="LANDING.PAGE_PRICING.LIFETIME" />
                                    </p>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-row">
                                  <div className="inner-col">
                                    <div className="name">
                                      <FormattedMessage id="LANDING.PAGE_PRICING.SMALL" />
                                    </div>
                                    <p className="desc">
                                      <FormattedMessage id="LANDING.PAGE_PRICING.r2_5_USERS" />
                                    </p>
                                  </div>
                                  <div className="inner-col">
                                    <div className="price">
                                      <FormattedMessage id="LANDING.PAGE_PRICING.PRICE_SMALL" />
                                    </div>
                                    <p className="per-user">
                                      <FormattedMessage id="LANDING.PAGE_PRICING.PER_USER" />
                                    </p>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-row">
                                  <div className="inner-col">
                                    <div className="name">
                                      <FormattedMessage id="LANDING.PAGE_PRICING.MEDIUM" />
                                    </div>
                                    <p className="desc">
                                      <FormattedMessage id="LANDING.PAGE_PRICING.r6_20_USERS" />
                                    </p>
                                  </div>
                                  <div className="inner-col">
                                    <div className="price">
                                      <FormattedMessage id="LANDING.PAGE_PRICING.PRICE_MEDIUM" />
                                    </div>
                                    <p className="per-user">
                                      <FormattedMessage id="LANDING.PAGE_PRICING.PER_USER" />
                                    </p>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-row">
                                  <div className="inner-col">
                                    <div className="name">
                                      <FormattedMessage id="LANDING.PAGE_PRICING.LARGE" />
                                    </div>
                                    <p className="desc">
                                      <FormattedMessage id="LANDING.PAGE_PRICING.r21_50_USERS" />
                                    </p>
                                  </div>
                                  <div className="inner-col">
                                    <div className="price">
                                      <FormattedMessage id="LANDING.PAGE_PRICING.PRICE_LARGE" />
                                    </div>
                                    <p className="per-user">
                                      <FormattedMessage id="LANDING.PAGE_PRICING.PER_USER" />
                                    </p>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-row">
                                  <div className="inner-col">
                                    <div className="name">
                                      <FormattedMessage id="LANDING.PAGE_PRICING.ENTERPRISE" />
                                    </div>
                                    <p className="desc">
                                      <FormattedMessage id="LANDING.PAGE_PRICING.r51_PLUS_USERS" />
                                    </p>
                                  </div>
                                  <div className="inner-col">
                                    <div className="price">
                                      <FormattedMessage id="LANDING.PAGE_PRICING.PRICE_ENTERPRISE" />
                                    </div>
                                    <p className="per-user">
                                      <FormattedMessage id="LANDING.PAGE_PRICING.PER_USER" />
                                    </p>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div className="col">
                            <h2>
                              <FormattedMessage id="LANDING.PAGE_PRICING.EVERY_PLAN_INCLUDES" />
                            </h2>

                            <ul className="details">
                              <li>
                                <div className="row">
                                  <div className="col">
                                    <FaCubes />
                                  </div>
                                  <div className="col">
                                    <h3>
                                      <FormattedMessage id="LANDING.PAGE_PRICING.ALL_FEATURES" />
                                    </h3>
                                    <p>
                                      <FormattedMessage id="LANDING.PAGE_PRICING.DESC1" />
                                    </p>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="row">
                                  <div className="col">
                                    <FaCloudDownloadAlt />
                                  </div>
                                  <div className="col">
                                    <h3>
                                      <FormattedMessage id="LANDING.PAGE_PRICING.LIFETIME_UPDATES" />
                                    </h3>
                                    <p>
                                      <FormattedMessage id="LANDING.PAGE_PRICING.DESC2" />
                                    </p>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="row">
                                  <div className="col">
                                    <FaComments />
                                  </div>
                                  <div className="col">
                                    <h3>
                                      <FormattedMessage id="LANDING.PAGE_PRICING.SUPPORT_YOU_CAN_RELY_ON" />
                                    </h3>
                                    <p>
                                      <FormattedMessage id="LANDING.PAGE_PRICING.DESC3" />
                                    </p>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="row">
                          <h2>
                            <FormattedMessage id="LANDING.PAGE_PRICING.PRICE_CALCULATOR" />
                          </h2>
                        </div>

                        <div className="row">
                          <div className="calc-wrapper">
                            {showForm}
                            <div className="text-side">
                              {errors &&
                                errors.submit &&
                                showSubmitError(errors.submit.message)}
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>

                    <Herofooter />
                  </main>
                </div>
              </article>
            </article>
          </tpnet-content>
        </div>
      </div>
    </Layout>
  )
}

export default injectIntl(PricingPage)
